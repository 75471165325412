/**
 * Has cookie ?
 * @param {string} name Cookie name.
 * @returns {boolean}
 */
export function hasCookie(name) {
  const cookies = `; ${document.cookie}`;

  if (cookies.includes(`; ${name}=`)) {
    return true;
  }

  return false;
}

/**
 * Get cookie value.
 * @param {string} name Cookie name.
 * @returns {string|null}
 */
export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }

  return null;
}
