import { reactive } from 'petite-vue';
import { setPaddingBody, removePaddingBody } from '../utils/scrollbar.js';

export default reactive({
  isOpenMenu: false,
  isDarkMode: false,
  modals: [],
  menu(state) {
    if (state === 'show') {
      this.isOpenMenu = true;
      setPaddingBody();
      return;
    }

    this.isOpenMenu = false;
    removePaddingBody();
  },
  modal(state, id, props = {}) {
    if (state === 'show') {
      this.modals.forEach((modal) => {
        if (modal.$template === id) {
          modal.show(props);
        }
      });
    }
    if (state === 'hide') {
      this.modals.forEach((modal) => {
        modal.hide();
      });
    }
  },
});
