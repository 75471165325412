/**
 * getScrollbarWidth
 * @returns {number}
 */
function getScrollbarWidth() {
  const div = document.createElement('div');

  div.style.cssText = `
    visibility: hidden;
    position: fixed;
    top: -1000px;
    z-index: -10;
    opacity: 0;
    overflow-y: scroll;
    width: 50px;
    height: 50px;
  `;

  document.body.append(div);
  const scrollbarWidth = div.offsetWidth - div.clientWidth;

  div.remove();

  return scrollbarWidth;
}

/**
 * setPaddingBody
 */
function setPaddingBody() {
  document.body.style.setProperty('overflow', 'hidden');
  document.body.style.setProperty('padding-right', `${getScrollbarWidth()}px`);
}

/**
 * removePaddingBody
 */
function removePaddingBody() {
  document.body.style.removeProperty('overflow');
  document.body.style.removeProperty('padding-right');
}

export { setPaddingBody, removePaddingBody, getScrollbarWidth };
