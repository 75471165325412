import ouibounce from 'ouibounce';
import $store from './modules/store.js';
import debounce from './utils/debounce.js';
import { hasCookie } from './utils/cookies.js';

const modal = ouibounce(document.querySelector('#modal-exit'), {
  cookieExpire: 7,
  cookieName: 'maddyness_exitpopup',
  callback() {
    $store.modal('show', '#modal-on-leave');
  },
});

const openModalDebounce = debounce(() => {
  modal.fire();
  modal.disable();
}, 60000);

/**
 * Open the modal.
 * @returns {void}
 */
function openModal() {
  if (hasCookie('maddyness_exitpopup') || modal.isDisabled()) {
    return;
  }

  openModalDebounce();
}

if (!hasCookie('maddyness_exitpopup')) {
  openModal();
  window.addEventListener('scroll', openModal);
  window.addEventListener('click', openModal);
  window.addEventListener('keypress', openModal);
  window.addEventListener('mousemove', openModal);
}
